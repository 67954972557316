<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>Отзыв</v-toolbar-title>
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                    v-model="entity.trip"
                    :label="$t('src\\views\\reviews\\edit.2831')"
                    :items="trips"
                    item-text="name"
                    item-value="_id"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-menu
                    ref="menu"
                    v-model="activeDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="entity.date"
                            :label="$t('src\\views\\reviews\\edit.2478')"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            :error-messages="errors[0]"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="entity.date"
                        locale="ru"
                        first-day-of-week="1"
                        @change="activeDatePicker = false"
                    />
                </v-menu>
            </ValidationProvider>
            <div
                class="pa-6 mx-0 mb-4"
                style="border: 1px solid #00000061; border-radius: 4px;">
                <p>{{ $t('src\\views\\reviews\\edit.5293') }}</p>
                <v-select
                    v-model="entity.user"
                    :label="$t('src\\views\\reviews\\edit.8200')"
                    :items="users"
                    :item-text="userName"
                    item-value="_id"
                    outlined
                />
                <p>{{ $t('src\\views\\reviews\\edit.4939') }}</p>
                <v-row>
                    <v-col>
                        <v-img
                            v-if="entity.externalUser"
                            width="67px"
                            height="67px"
                            :src="imageSrc(entity.externalUser.avatar)" />
                        <v-file-input
                            :label="$t('src\\views\\reviews\\edit.3506')"
                            outlined
                            prepend-icon="mdi-image"
                            @change="setAvatar"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="entity.externalUser.firstname"
                            :label="$t('src\\views\\reviews\\edit.9726')"
                            outlined
                        />
                        <v-text-field
                            v-model="entity.externalUser.lastname"
                            :label="$t('src\\views\\reviews\\edit.3823')"
                            outlined
                        />
                    </v-col>
                </v-row>
            </div>
            <div
                class="pa-6 mx-0 mb-4"
                style="border: 1px solid #00000061; border-radius: 4px;">
                <p>{{ $t('src\\views\\reviews\\edit.269') }}</p>
                <v-row>
                    <v-col>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <v-select
                                v-model="entity.rating.overall"
                                :label="$t('src\\views\\reviews\\edit.2431')"
                                :items="[0,1,2,3,4,5]"
                                outlined
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="entity.rating.route"
                            :label="$t('src\\views\\reviews\\edit.2395')"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="entity.rating.food"
                            :label="$t('src\\views\\reviews\\edit.7438')"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="entity.rating.accommodation"
                            :label="$t('src\\views\\reviews\\edit.1114')"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="entity.rating.transport"
                            :label="$t('src\\views\\reviews\\edit.6411')"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                </v-row>
            </div>
            <div
                class="pa-6 mx-0 mb-4"
                style="border: 1px solid #00000061; border-radius: 4px;">
                <p>{{ $t('src\\views\\reviews\\edit.6069') }}</p>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-textarea
                        v-model="entity.text"
                        :label="$t('src\\views\\reviews\\edit.7201')"
                        auto-grow
                        outlined
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
            </div>

            <div
                class="pa-6 mx-0 mb-4"
                style="border: 1px solid #00000061; border-radius: 4px;">
                <p>{{ $t('src\\views\\reviews\\edit.1778') }}</p><br />
                <v-row>
                    <template v-for="(photo, index) in entity.pictures">
                        <template v-if="photo">
                            <v-col :key="`photos-${index}`" align="center">
                                <v-img :src="imageSrc(photo)" class="my-0" />
                                <v-btn
                                    class="mb-2"
                                    color="error"
                                    @click="removePhoto(index)">{{ $t('src\\views\\reviews\\edit.4315') }}</v-btn>
                            </v-col>
                        </template>
                    </template>
                </v-row>
                <v-file-input
                    :label="$t('src\\views\\reviews\\edit.3133')"
                    outlined
                    prepend-icon="mdi-image"
                    @change="setPhoto"
                />
            </div>

            <div
                class="pa-6 mx-0 mb-4"
                style="border: 1px solid #00000061; border-radius: 4px;">
                <p>{{ $t('src\\views\\reviews\\edit.5397') }}</p>
                <v-text-field
                    v-model="entity.proof.link"
                    :label="$t('src\\views\\reviews\\edit.8943')"
                    outlined
                />
                <v-img :src="imageSrc(entity.proof.picture)" />
                <v-file-input
                    :label="$t('src\\views\\reviews\\edit.42')"
                    outlined
                    prepend-icon="mdi-image"
                    @change="setPicture"
                />
            </div>

            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\reviews\\edit.56') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\reviews\\edit.3052') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '../../helpers';

    export default {
        name: 'UsersEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            imageSrc,
            valid: true,
            activeDatePicker: false
        }),
        computed: {
            ...mapState('reviews', ['entity']),
            ...mapState('users', {
                users: state => state.entities
            }),
            ...mapState('trips', {
                trips: state => state.entities
            }),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('reviews/get', { id: this.$route.params.id });
            } else {
                await store.commit('reviews/CLEAR_ENTITY');
            }
            await store.dispatch('users/fetch', {});
            await store.dispatch('trips/fetch', { itemsPerPage: 100000 });
        },
        methods: {
            async setPicture(file) {
                this.entity.proof.picture = await store.dispatch('files/upload', { file });
            },
            async setAvatar(file) {
                this.entity.externalUser.avatar = await store.dispatch('files/upload', { file });
            },
            async setPhoto(file) {
                const photo = await store.dispatch('files/upload', { file });
                this.entity.pictures.push(photo);
            },
            async removePhoto(index) {
                this.entity.pictures.splice(index, 1);
            },
            userName(user) {
                return `${user.firstname} ${user.lastname}`;
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('reviews/SET_ENTITY', this.entity);
                    await store.dispatch('reviews/save');
                    await this.$router.push({ name: 'reviews-list' });
                }
            },
            async remove() {
                await store.dispatch('reviews/delete', { id: this.entity._id });
                await this.$router.push({ name: 'reviews-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
